import React, { useState, useEffect, useRef } from "react";
import { withRouter, NavLink, useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from 'react-redux';

// Actions
import { getQuote, clearQuote, resetQuoteState } from '../../actions/quote';
import { createOrder, resetOrderState } from '../../actions/order';

// Components
import OrderForm from './components/OrderForm';
import Spinner from "../../components/Spinner/Spinner.js";

function Order(props) {

    const [showSpinner, setShowSpinner] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCreateOrder = data => {
        setShowSpinner(true);
        clearQuote();
        props.dispatch(createOrder(data, () => {
            setShowConfirmation(true);
        }));
    };

    let history = useHistory();

    const handleResetAll = () => {
        props.dispatch(resetQuoteState());
        props.dispatch(resetOrderState(() => {
            history.push("/app/quote");
        }));
    }

    return (
        <div id="order">
            {props.order && props.order.error &&
                <div className="error">{props.order.error}</div>
            }
            {showSpinner && <Spinner />}
            {props.order.order &&
                <div className="order-confirmation">
                    Thank you! Your order has been successfully booked. A customer service representative will be reaching out shortly to confirm your information.
            </div>}

            {!props.order.order && props.quote && !showConfirmation && (props.order && !props.order.error) &&
                <OrderForm quote={props.quote} holidays={props.holidays} handleCreateOrder={handleCreateOrder} setShowSpinner={setShowSpinner} />
            }

            <div className="link-container" onClick={() => handleResetAll()}>Get a new quote here.</div>

        </div >

    )

}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        quote: state.quote.quote,
        holidays: state.settings.holidays
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps)
)(Order);
