import React, { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, NavLink, useHistory } from "react-router-dom";
import PlacesAutocomplete from 'react-places-autocomplete';

// Components
import Spinner from "../../components/Spinner/Spinner.js";
import QuoteDetail from './components/QuoteDetail';

// Form
import { useForm, useFieldArray, Controller, ErrorMessage } from 'react-hook-form';

// Actions
import { createQuote, resetQuoteState } from '../../actions/quote';
import { resetOrderState } from '../../actions/order';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
library.add([faTrash, faPlus, faInfoCircle]);

function Quote(props) {

    const [showSpinner, setShowSpinner] = useState(false);
    const [numVehicles, setNumVehicles] = useState(1);
    const [pickupLocationError, setPickupLocationError] = useState(null);
    const [deliveryLocationError, setDeliveryLocationError] = useState(null);
    const [showOperableToolTip, setShowOperableToolTip] = useState(null);
    const [makesList, setMakesList] = useState([]);

    let history = useHistory();


    const handleResetAll = () => {
        props.dispatch(resetQuoteState());
        props.dispatch(resetOrderState(() => {
            history.push("/app/quote");
        }));
    }

    useEffect(() => {
        if (props.makes) {
            let makes = props.makes;
            let makesList = [];
            makes.forEach(item => {
                let modelsList = [];
                item.models.forEach((model) => {
                    modelsList.push({ label: model.model, value: model.model, pricingClass: model.pricingClass });
                });
                makesList.push({ label: item.make, value: item.make, models: modelsList });
            });
            setMakesList(makesList);
        }

    }, [props.makes]);

    const { register, handleSubmit, errors, control, watch, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            vehicles: [{ make: "Select One", model: "Select One", "Operable": "Yes" }]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "vehicles"
    });

    const tooltip = useRef();
    const handleClick = e => {
        if ((tooltip.current && tooltip.current.contains(e.target)) === false) {
            setShowOperableToolTip(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const toggleOperableToolTip = (index) => {
        setShowOperableToolTip(index);
    }

    const onSubmit = data => {
        data.portalId = "5fd049defb9fc5074a087d21";
        setShowSpinner(true);
        props.dispatch(createQuote(data, () => {
            setShowSpinner(false);
        }));
    };

    return (
        <div id="quote">

            {props.quote && props.quote.error &&
                <div className="error">{props.quote.error}</div>
            }

            {showSpinner && <Spinner />}

            {!props.quote &&
                <div className="app-intro">
                    <p>Welcome to the F45 Auto Shipping Portal.  The portal is an easy and convenient way to calculate a quote, have the quote sent to you via email and book an order for transport.</p>
                    <p>For auto transport moves that you will not be paying for direct but rather billed to F45, please email <a href="mailto:F45moves@suddath.com">F45moves@suddath.com</a> and an agent will be happy to assist you.</p>
                    <p>If you have any questions, feel free to use our chat feature below or contact us at <a href="mailto:autologistics@mccollisters.com">autologistics@mccollisters.com</a>.</p>
                    <p>We look forward to servicing your auto transport need!</p>
                </div>
            }

            {!props.quote &&
                <div className="order-link">Already have a quote? <NavLink to="/book">Book your order here.</NavLink></div>
            }

            {!props.quote &&

                <form className="quote-form" onSubmit={handleSubmit(onSubmit)}>

                    <input
                        type="hidden"
                        ref={register}
                        value="5fd049defb9fc5074a087d21"
                        name="portalId" />

                    <input
                        type="hidden"
                        ref={register}
                        value="f45"
                        name="instance" />


                    <div className="quote-form-row">
                        <div className="form-input">
                            <label htmlFor="pickup">Pick Up</label>
                            <Controller
                                as={
                                    <PlacesAutocomplete
                                        searchOptions={{
                                            componentRestrictions: {
                                                country: ['us'],
                                            },
                                            types: ["(regions)"]
                                        }}
                                        onSelect={(e) => { setValue("pickup", e); setPickupLocationError(null); }} onError={() => setPickupLocationError('Location not found.')}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {


                                            return (
                                                <div className="autocomplete-form-field">
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Enter Zip or City',
                                                            className: 'location-search-input',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {

                                                            suggestion.description = suggestion.description.replace(", USA", "");

                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';

                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )

                                        }
                                        }
                                    </PlacesAutocomplete>
                                }

                                control={control}
                                rules={{ required: "This field is required." }}
                                name="pickup"
                            />
                            {pickupLocationError && <p className="form-error-message" >{pickupLocationError}</p>}
                            <ErrorMessage errors={errors} name="pickup">
                                {({ message }) => <p className="form-error-message" >{message}</p>}
                            </ErrorMessage>
                        </div>
                        <div className="form-input">
                            <label htmlFor="delivery">Delivery</label>
                            <Controller
                                as={
                                    <PlacesAutocomplete
                                        searchOptions={{
                                            componentRestrictions: {
                                                country: ['us'],
                                            },
                                            types: ["(regions)"]
                                        }}
                                        onSelect={(e) => { setValue("delivery", e); setDeliveryLocationError(null); }} onError={() => setDeliveryLocationError('Location not found.')}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="autocomplete-form-field">
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Enter Zip or City',
                                                        className: 'location-search-input',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {

                                                        suggestion.description = suggestion.description.replace(", USA", "");

                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                }

                                control={control}
                                rules={{ required: "This field is required." }}
                                name="delivery"
                            />
                            {deliveryLocationError && <p className="form-error-message" >{deliveryLocationError}</p>}
                            <ErrorMessage errors={errors} name="delivery">
                                {({ message }) => <p className="form-error-message" >{message}</p>}
                            </ErrorMessage>
                        </div>
                        <div className="form-input">
                            <label htmlFor="transportType">Transport Type</label>
                            <Controller
                                as={<Select options={[{ label: "OPEN", value: "OPEN", }, { label: "ENCLOSED", value: "ENCLOSED" }]} placeholder="Select One" />}
                                control={control}
                                rules={{ required: "This field is required." }}
                                name="transportType"
                            />
                            <ErrorMessage errors={errors} name="transportType">
                                {({ message }) => <p className="form-error-message" >{message}</p>}
                            </ErrorMessage>
                        </div>
                    </div>

                    <div className="quote-form-row">
                        <div className="form-input">
                            <label htmlFor="customerFirstName">First Name</label>
                            <input type="text" name="customerFirstName" ref={register} />
                        </div>
                        <div className="form-input">
                            <label htmlFor="customerLastName">Last Name</label>
                            <input type="text" name="customerLastName" ref={register} />
                        </div>
                    </div>

                    <div className="quote-form-vehicles">
                        {fields.map((item, index) => {

                            let selectedMake;
                            selectedMake = watch(`vehicles[${index}].make`);

                            return (
                                <div key={item.id} className="quote-form-row sub-form">
                                    <div className="form-input">
                                        <label htmlFor={`vehicles[${index}].make`}>Make</label>

                                        <Controller
                                            as={<Select
                                                options={makesList}
                                                placeholder="Select One"
                                            />
                                            }
                                            control={control}
                                            name={`vehicles[${index}].make`}
                                            defaultValue={item.make}
                                            rules={{ required: true, validate: value => value !== "Select One" }}
                                        />

                                        <ErrorMessage errors={errors} name={`vehicles[${index}].make`}>
                                            {() => <p className="form-error-message" >Please select a make.</p>}
                                        </ErrorMessage>


                                    </div>
                                    <div className="form-input">
                                        <label htmlFor={`vehicles[${index}].model`}>Model</label>

                                        {selectedMake && <div><Controller
                                            as={<Select options={selectedMake.models} placeholder="Select One" />}
                                            control={control}
                                            rules={{ validate: value => value !== 'Select One' }}
                                            name={`vehicles[${index}].model`}
                                            rules={{ required: "This field is required." }}
                                            defaultValue={item.model}
                                        />
                                            <ErrorMessage errors={errors} name={`vehicles[${index}].model`}>
                                                {() => <p className="form-error-message" >Please select a model.</p>}
                                            </ErrorMessage></div>

                                        }

                                    </div>

                                    <div className="form-input">
                                        <label htmlFor={`vehicles[${index}].operable`}>Is Vehicle Operable? <FontAwesomeIcon onClick={() => toggleOperableToolTip(index)} className="icon-info-circle" icon="info-circle" /></label>
                                        {showOperableToolTip === index && <div className="form-tooltip" ref={tooltip}>An operable vehicle means it can brake, roll, steer and start independently.</div>}
                                        <Controller
                                            as={<Select options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]} placeholder="Select One" />}
                                            control={control}
                                            rules={{ validate: value => value !== 'Is Operable' }}
                                            defaultValue={item.operable}
                                            rules={{ required: "This field is required." }}
                                            name={`vehicles[${index}].operable`}
                                        />

                                        <ErrorMessage errors={errors} name={`vehicles[${index}].operable`}>
                                            {() => <p className="form-error-message" >Select if vehicle is operable.</p>}
                                        </ErrorMessage>

                                    </div>

                                    <div className="form-input-icon-container">
                                        <button className="form-input-icon" onClick={(e) => {
                                            e.preventDefault();
                                            remove(index);
                                            setNumVehicles(numVehicles - 1);
                                        }
                                        }><FontAwesomeIcon icon="trash" /></button>
                                    </div>


                                </div>
                            )

                        }

                        )}
                    </div>


                    <div className="form-button-panel" >
                        {numVehicles < 3 &&
                            <button
                                className="form-add"
                                type="button"
                                onClick={() => {
                                    append({ make: "Select Make", model: "Select Model", operable: "Is Operable" });
                                    setNumVehicles(numVehicles + 1);
                                }}
                            >
                                <FontAwesomeIcon icon="plus" /> Another Vehicle
                </button>
                        }
                    </div>

                    <p>Please enter your email address below if you would like receive an email confirmation of your quote to book at a later date. Don’t worry, we’re not going to sell it, trade it, or abuse any information you submit to us.</p>
                    <div id="email-row" className="quote-form-row">
                        <div className="form-input">
                            <label htmlFor="customerEmail">Email Address (optional)</label>
                            <input type="text" name="customerEmail" ref={register} />
                        </div>
                    </div>
                    <div className="form-button-panel" >
                        <button className="form-submit" type="submit">Get Quote</button>
                    </div>

                </form>
            }

            {props.quote &&
                <QuoteDetail quote={props.quote} serviceLevels={props.serviceLevels} />
            }

            <div className="link-container" onClick={() => handleResetAll()}>Cancel and start a new quote.</div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        makes: state.settings.makes,
        quote: state.quote.quote,
        serviceLevels: state.settings.serviceLevels
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps)
)(Quote);
