export default (state = { quote: null, error: null }, action) => {
    switch (action.type) {
        case "CREATE_QUOTE":
            return { ...state, quote: action.quote };
        case "CLEAR_QUOTE":
            return { state: { quote: null, error: null } };
        case "GET_QUOTE":
            return { ...state, quote: action.quote };
        case "QUOTE_ERROR":
            return { ...state, error: action.payload };
        case "CLEAR_QUOTE_ERROR":
            return { ...state, error: '' };
        case "RESET_QUOTE_STATE":
            return { quote: null, error: null };
        default:
            return state;
    }
};

