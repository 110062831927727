import axios from 'axios';
import config from '../config/keys';
const ROOT = config.rootUrl;

export const createQuote = (formData, callback) => {
    return (dispatch) => {
        dispatch(clearQuoteError());
        return axios.post(`${ROOT}/quote/customer`, formData)
            .then(response => {
                if (response.data.error) {
                    dispatch(quoteError(response.data.error));
                } else {
                    dispatch(createQuoteSuccess(response.data));

                }
                callback();
            })
            .catch(error => {
                dispatch(quoteError("Whoops! We seem to be having an issue calculating your quote. Please email us at autologistics@mccollisters.com and we will be happy to assist."));
                throw (error);
                callback();
            });
    };
};


export const createQuoteSuccess = data => ({
    type: "CREATE_QUOTE",
    quote: data
});


export const getQuote = (formData, callback) => {

    return (dispatch) => {
        dispatch(clearQuoteError());
        return axios.post(`${ROOT}/quote/customer/find`, formData)
            .then(response => {
                if (response.data.error) {
                    dispatch(quoteError(response.data.error));
                } else {
                    dispatch(getQuoteSuccess(response.data));
                    callback();
                }
            })
            .catch(error => {
                dispatch(quoteError("Whoops! We seem to be having an issue finding your quote. Please email us at autologistics@mccollisters.com and we will be happy to assist."));
                throw (error);
            });
    };
};

export const getQuoteSuccess = data => ({
    type: "GET_QUOTE",
    quote: data
});

export function quoteError(error) {
    return {
        type: "QUOTE_ERROR",
        payload: error
    };
}

export function clearQuoteError() {
    return {
        type: "CLEAR_QUOTE_ERROR"
    };
}

export function clearQuote() {
    return {
        type: "CLEAR_QUOTE"
    };
}

export function resetQuoteState() {
    return {
        type: "RESET_QUOTE_STATE"
    };
}
