// 3rd Party Libraries
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import quoteReducer from '../reducers/quote';
import orderReducer from '../reducers/order';
import settingsReducer from '../reducers/settings';

import { getSettings } from '../actions/settings';


const rootReducer = combineReducers({
    quote: quoteReducer,
    order: orderReducer,
    settings: settingsReducer
})

const persistConfig = {
    key: 'root',
    storage
};

const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);


store.dispatch(getSettings());

// FOR TESTING ONLY:
// store.subscribe(() => {
//     const state = store.getState();
//     console.log(state);
// });


export { persistor, store };

// const middlewares = [thunk];

// // Creates the Redux store with reducers and middleware
// export default () => {
//     const store = createStore(
//         combineReducers({
//             quote: quoteReducer,
//             order: orderReducer,
//             settings: settingsReducer
//         }),
//         applyMiddleware(...middlewares)
//     );

//     store.dispatch(getSettings());


//     // FOR TESTING ONLY:
//     store.subscribe(() => {
//         const state = store.getState();
//         console.log(state);
//     });

//     return store;
// }

