export default (state = { order: null, error: null }, action) => {
    switch (action.type) {
        case "CREATE_ORDER":
            return { ...state, order: action.order };
        case "ORDER_ERROR":
            return { ...state, error: action.payload };
        case "CLEAR_ORDER_ERROR":
            return { ...state, error: '' };
        case "RESET_ORDER_STATE":
            return { order: null, error: null };
        default:
            return state;
    }
};

