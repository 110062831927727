import axios from 'axios';
import config from '../config/keys';
const ROOT = config.rootUrl;

export const getSettings = () => {
    return (dispatch) => {

        return axios.get(`${ROOT}/settings/customer`)
            .then(response => {
    
                dispatch(getSettingsSuccess(response.data));
            })
            .catch(error => {
                throw (error);
            });
    };
}

export const getSettingsSuccess = data => ({
    type: "GET_SETTINGS",
    settings: data
});