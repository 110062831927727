import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


// Views
import Quote from "../views/Quote/Quote";
import Order from "../views/Order/Order";
import Find from "../views/Find/Find";

import F45 from "../assets/F45-logo.png";
import Suddath from "../assets/Suddath-logo.jpg";
import McCollisters from "../assets/McCollisters-logo.png";

// Global Styles
import "../styles/styles.scss";

function AppRouter(props) {

    return (
        <Router>
            <div className="app">

                <div className="app-heading">
                    <img id="primary-logo" src={F45} />
                    <div className="app-sub-heading">
                        <div id="line-1">
                            <p>Managed by</p>
                            <img id="logo-2" src={Suddath} />
                        </div>
                        <div id="line-2">
                            <p>Powered by</p>
                            <img id="logo-3" src={McCollisters} />
                        </div>
                    </div>
                </div>

                <div className="app-container">
                    <div className="view-container">
                        <Switch>
                            <Route path="/quote"><Quote /></Route>
                            <Route path="/order"><Order /></Route>
                            <Route path="/book"><Find /></Route>
                            <Route path="/"><Quote /></Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </Router>
    )
}


export default AppRouter;