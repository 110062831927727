import axios from 'axios';
import config from '../config/keys';
const ROOT = config.rootUrl;

export const createOrder = (formData) => {
    return (dispatch) => {
        return axios.post(`${ROOT}/order/customer`, formData)
            .then(response => {
                if (response.data.error) {
                    dispatch(orderError(response.data.error));
                } else {
                    dispatch(createOrderSuccess(response.data));
                    // history.push(`/quote/${response.data._id}`);
                }
            })
            .catch(error => {
                dispatch(orderError("Whoops! We seem to be having an issue booking your order. Please email us at autologistics@mccollisters.com and we will be happy to assist."));
                throw (error);
            });
    };
};


export const createOrderSuccess = data => ({
    type: "CREATE_ORDER",
    order: data
});

export function orderError(error) {
    return {
        type: "ORDER_ERROR",
        payload: error
    };
}

export function clearOrderError() {
    return {
        type: "CLEAR_ORDER_ERROR"
    };
}


export function resetOrderState(callback) {
    callback();
    return {
        type: "RESET_ORDER_STATE"
    };
}